import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import {
	generalCountAdminAction,
	getEarningsReportByMonthAction,
	getGeneralCountAction,
	getSessionChartDataAction,
	getSessionReportAction,
	getTrainerTotalEarningByMonthChartAction,
	getTrainerTotalSessionByMonthAction,
	getTrainerTotalSessionByMonthChartAction,
	totalEarningByMonthAdminAction,
	totalEarningByMonthAdminChartAction,
	totalSessionByMonthAdminAction,
	totalSessionByMonthAdminChartAction
} from '../../redux/actions/reportsActions';
import { Bar } from 'react-chartjs-2';
import { options, optionsStacked } from './chartOptions';
import ReportHeader from './ReportHeader';
import ReportHeaderTrainer from './ReportHeaderTrainer'
import Chart from 'chart.js/auto';
import ReportStyles from './Report.module.css';
import { Link } from 'react-router-dom';
import IncomeChart from './IncomeChart';
import { months } from './months';
import {
	GENERAL_COUNT_ADMIN_RESET,
	GET_EARNING_BY_MONTH_REPORT_RESET,
	GET_GENERAL_COUNT_RESET,
	GET_SESSION_DATA_REPORT_RESET,
	GET_SESSION_REPORT_RESET,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET,
	TOTAL_EARNING_BY_MONTH_ADMIN_RESET,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET,
	TOTAL_SESSION_BY_MONTH_ADMIN_RESET
} from '../../redux/constants/reportsConstants';
import ChartSkeleton from '../../components/Skeletons/ChartSkeleton';
import DashboardCard from '../../components/Skeletons/DashboardCard';
import RangeDatePickerModal from './RangeDatePickerModal';
import moment from 'moment';
import { sortData } from '../../utils/phonemask';
import { logout } from '../../redux/actions/userActions';
import { toast } from 'react-toastify';
import { amountToStringWithSymbol } from '../../utils/numbers';

const ReportScreen = ({ history }) => {
	const dispatch = useDispatch();

	// const [month, setMonth] = useState(new Date().getMonth() + 1);
	const [fromDate, setFromDate] = useState(moment().startOf('month').format('MM-DD-YYYY'));
	const [toDate, setToDate] = useState(moment().endOf('month').format('MM-DD-YYYY'));

	const userLogin = useSelector((state) => state.userLogin);
	const getUserInfo = useSelector((state) => state.getUserInfo);
	const sessionReport = useSelector((state) => state.sessionReport);
	const sessionChartReport = useSelector((state) => state.sessionChartReport);
	const generalCountReport = useSelector((state) => state.generalCountReport);
	const earningReportByMonth = useSelector((state) => state.earningReportByMonth);
	const getTrainerSessionByMonth = useSelector((state) => state.getTrainerSessionByMonth);
	const getTrainerSessionByMonthChart = useSelector((state) => state.getTrainerSessionByMonthChart);
	const getTrainerEarningByMonthChart = useSelector((state) => state.getTrainerEarningByMonthChart);

	const generalCountAdmin = useSelector((state) => state.generalCountAdmin);
	const totalSessionByMonthAdmin = useSelector((state) => state.totalSessionByMonthAdmin);
	const totalSessionByMonthAdminChart = useSelector((state) => state.totalSessionByMonthAdminChart);
	const totalEarningByMonthAdmin = useSelector((state) => state.totalEarningByMonthAdmin);
	const totalEarningByMonthAdminChart = useSelector((state) => state.totalEarningByMonthAdminChart);

	useEffect(
		() => {
			if (getUserInfo && getUserInfo.userInfo && !getUserInfo.userInfo.status && getUserInfo.userInfo.code === "invalid_token") {
				toast.error(getUserInfo.userInfo.message);
				dispatch(logout());
				history.push('/login');
			} else if (getUserInfo && getUserInfo.userInfo && !getUserInfo.userInfo.status && getUserInfo.userInfo.message === "jwt malformed") {
				toast.error(getUserInfo.userInfo.message);
				dispatch(logout());
				history.push('/login');
			} else if (userLogin && userLogin.userInfo && !userLogin.userInfo.status && userLogin.userInfo.code === "invalid_token") {
				toast.error(userLogin.userInfo.message);
				dispatch(logout());
				history.push('/login');
			} else if (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				(getUserInfo.userInfo.data.role === 'gym' ||
					getUserInfo.userInfo.data.role === 'gymadmin' ||
					getUserInfo.userInfo.data.role === 'superadmin')
			) {
				dispatch(getGeneralCountAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(getSessionReportAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(getSessionChartDataAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(getEarningsReportByMonthAction({ fromDate: fromDate, toDate: toDate }));
			} else if (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.role === 'trainer'
			) {
				dispatch(getTrainerTotalSessionByMonthAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(getTrainerTotalSessionByMonthChartAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(getTrainerTotalEarningByMonthChartAction({ fromDate: fromDate, toDate: toDate }));
			} else if (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.role === 'admin'
			) {
				dispatch(generalCountAdminAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(totalSessionByMonthAdminAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(totalSessionByMonthAdminChartAction({ fromDate: fromDate, toDate: toDate }));
				dispatch(totalEarningByMonthAdminAction());
				dispatch(totalEarningByMonthAdminChartAction());
			}
		},
		[history, getUserInfo, dispatch, userLogin]
	);

	useEffect(
		() => {
			return () => {
				dispatch({ type: GET_GENERAL_COUNT_RESET });
				dispatch({ type: GET_SESSION_REPORT_RESET });
				dispatch({ type: GET_SESSION_DATA_REPORT_RESET });
				dispatch({ type: GET_EARNING_BY_MONTH_REPORT_RESET });
				dispatch({ type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET });
				dispatch({ type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET });
				dispatch({ type: GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET });
				dispatch({ type: GENERAL_COUNT_ADMIN_RESET });
				dispatch({ type: TOTAL_SESSION_BY_MONTH_ADMIN_RESET });
				dispatch({ type: TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET });
				dispatch({ type: TOTAL_EARNING_BY_MONTH_ADMIN_RESET });
				dispatch({ type: TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET });
			};
		},
		[dispatch]
	);

	// const onChangeMonthHandler = (m) => {
	// 	setMonth(m);
	// 	dispatch(getSessionReportAction({ month: m }));
	// 	dispatch(getSessionChartDataAction({ month: m }));
	// };

	const datePickerHandler = (startDate, endDate) => {
		console.log(startDate, 'startDate', endDate, 'toDate');

		if (getUserInfo &&
			getUserInfo.userInfo &&
			getUserInfo.userInfo.data &&
			(getUserInfo.userInfo.data.role === 'gym' ||
				getUserInfo.userInfo.data.role === 'gymadmin' ||
				getUserInfo.userInfo.data.role === 'superadmin')
		) {
			dispatch(getGeneralCountAction({ fromDate: startDate, toDate: endDate }));
			dispatch(getSessionReportAction({ fromDate: startDate, toDate: endDate }));
			dispatch(getSessionChartDataAction({ fromDate: startDate, toDate: endDate }));
			dispatch(getEarningsReportByMonthAction({ fromDate: startDate, toDate: endDate }));
		} else if (
			getUserInfo &&
			getUserInfo.userInfo &&
			getUserInfo.userInfo.data &&
			getUserInfo.userInfo.data.role === 'trainer'
		) {
			dispatch(getTrainerTotalSessionByMonthAction({ fromDate: startDate, toDate: endDate }));
			dispatch(getTrainerTotalSessionByMonthChartAction({ fromDate: startDate, toDate: endDate }));
			dispatch(getTrainerTotalEarningByMonthChartAction({ fromDate: startDate, toDate: endDate }));
		} else if (
			getUserInfo &&
			getUserInfo.userInfo &&
			getUserInfo.userInfo.data &&
			getUserInfo.userInfo.data.role === 'admin'
		) {
			dispatch(generalCountAdminAction({ fromDate: startDate, toDate: endDate }));
			dispatch(totalSessionByMonthAdminAction({ fromDate: startDate, toDate: endDate }));
			dispatch(totalSessionByMonthAdminChartAction({ fromDate: startDate, toDate: endDate }));
			dispatch(totalEarningByMonthAdminAction());
			dispatch(totalEarningByMonthAdminChartAction());
		}


		// dispatch(getGeneralCountAction({ fromDate: startDate, toDate: endDate }));
		// dispatch(getSessionReportAction({ fromDate: startDate, toDate: endDate }));
		// dispatch(getSessionChartDataAction({ fromDate: startDate, toDate: endDate }));
	};



	return (
		<Content currentMenu='dashboard' headerTitle='Dashboard' addBtn={false} search={false}>

			<RangeDatePickerModal data={{
				fromDate,
				toDate,
				setFromDate,
				setToDate,
				datePickerHandler
			}} />

			{getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.role === 'admin' ? (
				<div className={ReportStyles.reportContent}>
					<div className={ReportStyles.leftContainer}>
						{generalCountAdmin && generalCountAdmin.loading ? (
							<DashboardCard />
						) : (
							<ReportHeader
								data={{ generalCountReport: generalCountAdmin }}
								role={getUserInfo.userInfo.data.role}
							// style={{ marginTop: '2rem' }}
							/>
						)}
						{totalSessionByMonthAdminChart && totalSessionByMonthAdminChart.loading ? (
							<ChartSkeleton />
						) : (
							totalSessionByMonthAdminChart &&
							totalSessionByMonthAdminChart.report &&
							totalSessionByMonthAdminChart.report.data && (
								<div className={ReportStyles.chartContent}>
									<div className={ReportStyles.chartContentHeader}>
										<h1 className={ReportStyles.chartContentHeading}>Session Chart</h1>
									</div>
									{totalSessionByMonthAdminChart.report.data &&
										Object.keys(totalSessionByMonthAdminChart.report.data).length > 0 ? (
										<Bar
											data={totalSessionByMonthAdminChart.report.data}
											options={options}
											className={ReportStyles.report_chart_container}
										/>
									) : (
										<p className='textCapitalize'>No Session Data Found!</p>
									)}
								</div>
							)
						)}
						{totalEarningByMonthAdminChart && totalEarningByMonthAdminChart.loading ? (
							<ChartSkeleton />
						) : (
							totalEarningByMonthAdminChart &&
							totalEarningByMonthAdminChart.report &&
							totalEarningByMonthAdminChart.report.data && (
								<div className={ReportStyles.chartContent}>
									<div className={ReportStyles.chartContentHeader}>
										<h1 className={ReportStyles.chartContentHeading}>Earning by Month Chart  (Year {new Date().getFullYear()})</h1>
									</div>
									{totalEarningByMonthAdminChart.report.data &&
										Object.keys(totalEarningByMonthAdminChart.report.data).length > 0 ? (
										<Bar
											data={totalEarningByMonthAdminChart.report.data}
											options={optionsStacked}
											className={ReportStyles.report_chart_container}
										/>
									) : (
										<p className='textCapitalize'>No Earning Data Found!</p>
									)}
								</div>
							)
						)}
					</div>
					<div className={ReportStyles.rightContainer}>
						{totalEarningByMonthAdmin &&
							totalEarningByMonthAdmin.report &&
							totalEarningByMonthAdmin.report.data && (
								<div className={ReportStyles.rightContent2}>
									<h1 className={ReportStyles.chartContentHeading}>Monthwise Earning  (Year {new Date().getFullYear()})</h1>
									{
										totalEarningByMonthAdmin.report.data &&
										totalEarningByMonthAdmin.report.data.length > 0 &&
										totalEarningByMonthAdmin.report.data.map((res, index) => (
											<div key={index + 1} className={ReportStyles.trainerList}>
												<div className={ReportStyles.trainerListData}>
													<div>
														<h4 className={ReportStyles.trainerListName}>
															{months.length > 0 && months.map((m) => m.month === res.month && m.name)}
														</h4>
														<h6 className={ReportStyles.trainerListSessions}>
															<span>{amountToStringWithSymbol(res.earning)}</span><br /> {'(' + res.total_session + ' Sessions)'}
														</h6>
														{/* <p className={ReportStyles.trainerListEarning}>{res.total_session + 'sessions'}</p> */}
													</div>
												</div>
											</div>
										))}
								</div>
							)}
						{totalSessionByMonthAdmin &&
							totalSessionByMonthAdmin.report &&
							totalSessionByMonthAdmin.report.data &&
							totalSessionByMonthAdmin.report.data.length > 0 && (
								<div className={ReportStyles.rightContent4}>
									<h1 className={ReportStyles.chartContentHeading}>Monthwise Sessions (Year {new Date().getFullYear()})</h1>
									{totalSessionByMonthAdmin.report.data.map((res, index) => (
										<div key={index + 1} className={ReportStyles.trainerList}>
											<div className={ReportStyles.trainerListData}>
												<div>
													<h4 className={ReportStyles.trainerListName}>
														{months.length > 0 && months.map((m) => m.month === res.month && m.name)}
													</h4>
													<h6 className={ReportStyles.trainerListSessions}>
														<svg className='navigation__icon'>
															<use xlinkHref={`/assets/sprite.svg#icon-stopwatch`} />
														</svg>	<span>{res.total}</span> Sessions
													</h6>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
					</div>
				</div>
			) : getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.role === 'trainer' ? (
				<div className={ReportStyles.reportContent} style={{ height: '60vh' }}>
					<div className={ReportStyles.leftContainer}>
						{getTrainerSessionByMonth && getTrainerSessionByMonth.loading ? (
							<DashboardCard />
						) : (
							<ReportHeaderTrainer
								role={getUserInfo.userInfo.data.role}
								data={{
									generalCountReport: getTrainerSessionByMonth,
									descriptionSessions: '',
									descriptionEarnings: '', //Include utilities
									descriptionGrowth: '',
									labelEarning: 'Total Amount Due' //'Total Amount Due'
								}} />
						)}
						{getTrainerSessionByMonthChart && getTrainerSessionByMonthChart.loading ? (
							<ChartSkeleton />
						) : (
							getTrainerSessionByMonthChart &&
							getTrainerSessionByMonthChart.report &&
							getTrainerSessionByMonthChart.report.data && (
								<div className={ReportStyles.chartContent}>
									<div className={ReportStyles.chartContentHeader}>
										<h1 className={ReportStyles.chartContentHeading}>Session Chart</h1>
									</div>
									{
										getTrainerSessionByMonthChart.report.data &&
											Object.keys(getTrainerSessionByMonthChart.report.data).length > 0 ? (
											<Bar
												data={getTrainerSessionByMonthChart.report.data}
												options={options}
												className={ReportStyles.report_chart_container}
											/>
										) : (
											<p className='textCapitalize'>No Session Data Found!</p>
										)}
								</div>
							)
						)}
					</div>
					{getTrainerEarningByMonthChart &&
						getTrainerEarningByMonthChart.report &&
						getTrainerEarningByMonthChart.report.data && (
							<div className={ReportStyles.rightContainer}>
								<div className={ReportStyles.rightContent} >
									<h1 className={ReportStyles.chartContentHeading}>Your Earning History (Year {new Date().getFullYear()})</h1>
									{
										getTrainerEarningByMonthChart.report.data.length > 0 ? (
											getTrainerEarningByMonthChart.report.data.map((res, index) => (
												<div key={index + 1} className={ReportStyles.trainerList}>
													<div className={ReportStyles.trainerListData}>
														<div>
															<h4 className={ReportStyles.trainerListName}>
																{months.length > 0 && months.map((m) => m.month === res.month && m.name)}
															</h4>
															<h6 className={ReportStyles.trainerListSessions}>
																$ <span>{res.earning}</span>
															</h6>
															<p className={ReportStyles.trainerListEarning}>{res.total_session + ' Sessions'}</p>
														</div>
													</div>
												</div>
											))
										) : (
											<p className='textCapitalize'>No Trainer Earning Data Found!</p>
										)}
								</div>
							</div>
						)}
				</div>
			) : (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				<div className={ReportStyles.reportContent}>
					<div className={ReportStyles.leftContainer}>
						{generalCountReport && generalCountReport.loading ? (
							<DashboardCard />
						) : (
							<ReportHeaderTrainer
								role={getUserInfo.userInfo.data.role}
								data={{
									generalCountReport,
									labelEarning: 'Total Earnings',
									descriptionSessions: '',
									descriptionEarnings: '',//Include utilities
									descriptionGrowth: ''
								}} />
						)}
						{sessionChartReport && sessionChartReport.loading ? (
							<ChartSkeleton />
						) : (
							<div className={ReportStyles.chartContent}>
								<div className={ReportStyles.chartContentHeader}>
									<h1 className={ReportStyles.chartContentHeading}>Trainer Session Chart</h1>
									{/* {sessionChartReport &&
										sessionChartReport.report &&
										sessionChartReport.report.data &&
										Object.keys(sessionChartReport.report.data).length > 0 &&
										<ReportToggle showfilter={true} data={{ month }} onChangeMonthHandler={onChangeMonthHandler} />
									} */}
								</div>

								{sessionChartReport &&
									sessionChartReport.report &&
									sessionChartReport.report.data &&
									Object.keys(sessionChartReport.report.data).length > 0 ? (
									<Bar
										data={sessionChartReport.report.data}
										options={options}
										className={ReportStyles.report_chart_container}
									/>
								) : (
									<p>No report found</p>
								)}
							</div>
						)}
						{earningReportByMonth && earningReportByMonth.loading ? (
							<ChartSkeleton />
						) : (
							<IncomeChart earningReportByMonth={earningReportByMonth} />
						)}
					</div>
					<div className={ReportStyles.rightContainer}>
						<div className={ReportStyles.rightContent}>
							<div className='flex justifySpaceBetween flexWrap'>
								<h1 className={ReportStyles.chartContentHeading}>Trainer Sessions History</h1>
								<Link to='/all-trainer-sessions' className={ReportStyles.button}>
									View All
								</Link>
							</div>
							{sessionReport &&
								sessionReport.report &&
								sessionReport.report.data &&
								sessionReport.report.data.length > 0 ? (
								sortData(sessionReport.report.data).map((res, index) => (
									<Link
										to={`/trainer-sessions/${res.trainer._id}/${res.trainer.name}`}
										key={index + 1}
										className={ReportStyles.trainerList}>
										<div className={ReportStyles.trainerListData}>
											<img src={res.trainer.photo} alt={res.trainer.name} className={ReportStyles.trainerListImage} />
											<div>
												<h4 className={ReportStyles.trainerListName}>{res.trainer.name}</h4>
												<h6 className={ReportStyles.trainerListSessions}>
													<span>{res.session.toFixed(2)}</span> Sessions
												</h6>
												{res.earning && <p className={ReportStyles.trainerListEarning}>{'Total Earning $' + res.earning.toFixed(2)}</p>}
												{res.sessionEarning && <p className={ReportStyles.trainerListEarning}>{'Session Earning $' + res.sessionEarning.toFixed(2)}</p>}
												{
													res.reservationFees ?
														<p className={ReportStyles.trainerListEarning}>{'Reservation Earning $' + res.reservationFees.toFixed(2)}</p>
														:
														<p className={ReportStyles.trainerListEarning}>Reservation Earning $0</p>

												}

											</div>
										</div>
										<svg className={ReportStyles.trainerListIcon}>
											<use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
										</svg>
									</Link>
								))) : (
								<p>No report found</p>
							)}
						</div>
					</div>
				</div>
			)}
		</Content>
	);
};

export default ReportScreen;
