import {
	GET_SESSION_REPORT_FAIL,
	GET_SESSION_REPORT_REQUEST,
	GET_SESSION_REPORT_SUCCESS,
	GET_SESSION_BY_MONTH_REPORT_REQUEST,
	GET_SESSION_BY_MONTH_REPORT_SUCCESS,
	GET_SESSION_BY_MONTH_REPORT_FAIL,
	GET_EARNING_BY_MONTH_REPORT_REQUEST,
	GET_EARNING_BY_MONTH_REPORT_SUCCESS,
	GET_EARNING_BY_MONTH_REPORT_FAIL,
	GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST,
	GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS,
	GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL,
	GET_GENERAL_COUNT_REQUEST,
	GET_GENERAL_COUNT_SUCCESS,
	GET_GENERAL_COUNT_FAIL,
	GET_SESSION_DATA_REPORT_REQUEST,
	GET_SESSION_DATA_REPORT_SUCCESS,
	GET_SESSION_DATA_REPORT_FAIL,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL,
	GENERAL_COUNT_ADMIN_REQUEST,
	GENERAL_COUNT_ADMIN_SUCCESS,
	GENERAL_COUNT_ADMIN_FAIL,
	TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST,
	TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS,
	TOTAL_SESSION_BY_MONTH_ADMIN_FAIL,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL,
	TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST,
	TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS,
	TOTAL_EARNING_BY_MONTH_ADMIN_FAIL,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL
} from '../constants/reportsConstants';
import axios from 'axios';
import { BASE_URL } from './ip';

const utcTimeOffset = new Date().getTimezoneOffset();

export const getSessionReportAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_SESSION_REPORT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			// month=${formData.month}
			`${BASE_URL}/report/get-total-session-by-trainer-month-wise?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=${utcTimeOffset}`,
			config
		);

		dispatch({
			type: GET_SESSION_REPORT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_SESSION_REPORT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getSessionReportByMonthAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_SESSION_BY_MONTH_REPORT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-total-session-by-month?offset=${utcTimeOffset}`, config);

		dispatch({
			type: GET_SESSION_BY_MONTH_REPORT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_SESSION_BY_MONTH_REPORT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getEarningsReportByMonthAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_EARNING_BY_MONTH_REPORT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/report/get-total-earning-by-month-for-chart?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=${utcTimeOffset}`,
			config
		);

		dispatch({
			type: GET_EARNING_BY_MONTH_REPORT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_EARNING_BY_MONTH_REPORT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getTotalSalesReportByMonthAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-total-sales-by-month?offset=${utcTimeOffset}`, config);

		dispatch({
			type: GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getGeneralCountAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_GENERAL_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-general-count?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=${utcTimeOffset}`, config);

		dispatch({
			type: GET_GENERAL_COUNT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_GENERAL_COUNT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getSessionChartDataAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_SESSION_DATA_REPORT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/report/get-total-session-by-trainer-month-wise-for-chart?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=${utcTimeOffset}`,
			config
		);

		dispatch({
			type: GET_SESSION_DATA_REPORT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_SESSION_DATA_REPORT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getTrainerTotalSessionByMonthAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-general-count-trainer?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=${utcTimeOffset}`, config);

		dispatch({
			type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getTrainerTotalSessionByMonthChartAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/report/get-total-session-by-month-trainer-for-chart?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=-330`,
			config
		);

		dispatch({
			type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getTrainerTotalEarningByMonthChartAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-total-earning-by-month-trainer?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=-330`, config);

		dispatch({
			type: GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const generalCountAdminAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GENERAL_COUNT_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-general-count-admin?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=-330`, config);

		dispatch({
			type: GENERAL_COUNT_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GENERAL_COUNT_ADMIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const totalSessionByMonthAdminAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-total-session-by-month-admin?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=-330`, config);

		dispatch({
			type: TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TOTAL_SESSION_BY_MONTH_ADMIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const totalSessionByMonthAdminChartAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/report/get-total-session-by-month-admin-for-chart?fromDate=${formData.fromDate}&toDate=${formData.toDate}&offset=-330`,
			config
		);

		dispatch({
			type: TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const totalEarningByMonthAdminAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/report/get-total-earning-by-month-admin?offset=-330`, config);

		dispatch({
			type: TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TOTAL_EARNING_BY_MONTH_ADMIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const totalEarningByMonthAdminChartAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/report/get-total-earning-by-month-admin-for-chart?offset=-330`,
			config
		);

		dispatch({
			type: TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
