import React, { Fragment } from 'react';
import HeadingCard from '../../components/ReportCard/headingCards';
import './ReportHeader.css';

const ReportHeader = ({ data, role = '', style }) => {
	const { generalCountReport } = data;
	return (
		<Fragment>
			{generalCountReport &&
				generalCountReport.report &&
				generalCountReport.report.data && (
					<div className='reportHeader--tab'>
						{generalCountReport.report.data && (
							<HeadingCard
								label='Total Sessions'
								description='' //this year
								count={generalCountReport.report.data.total_session ? generalCountReport.report.data.total_session.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0}
								icon='stopwatch'
								style={style}
								textColor='black'
							/>
						)}
						{generalCountReport.report.data && (
							<HeadingCard
								label='Total Earnings'
								description='' //this year
								count={generalCountReport.report.data.total_earning ? generalCountReport.report.data.total_earning.toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0}
								icon='dollar'
								style={style}
								textColor='green'
							/>
						)}

						{/* {generalCountReport.report.data && (
							<HeadingCard
								label='Total Growth'
								description='' //from last year
								count={generalCountReport.report.data.growth_percentage ? generalCountReport.report.data.growth_percentage.toFixed(2) + ' %' : 0}
								icon={generalCountReport.report.data.growth_percentage > 0 ? 'grow' : 'trending-down'}
								style={style}
								textColor={generalCountReport.report.data.growth_percentage > 0 ? 'green' : 'red'}
							/>
						)} */}
						{role &&
							role === 'admin' &&
							<Fragment>
								{generalCountReport.report.data && (
									<HeadingCard
										label='Total Gyms'
										description='registered with us'
										count={generalCountReport.report.data.gymCount ? generalCountReport.report.data.gymCount.toFixed() : 0}
										icon='gym'
										style={style}
										textColor='black'
									/>
								)}

								{generalCountReport.report.data && (
									<HeadingCard
										label='Total Trainers'
										description='registered with us'
										count={generalCountReport.report.data.trainerCount ? generalCountReport.report.data.trainerCount : 0}
										icon='trainer'
										style={style}
										textColor='black'
									/>
								)}

								{generalCountReport.report.data && (
									<HeadingCard
										label='Total Users'
										description='registered with us'
										count={generalCountReport.report.data.userCount ? generalCountReport.report.data.userCount : 0}
										icon='users'
										style={style}
										textColor='black'
									/>
								)}
							</Fragment>
						}
					</div>
				)}
		</Fragment>
	);
};

export default ReportHeader;
